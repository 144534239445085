/* eslint-disable react/jsx-no-bind */
import styles from './ImageSlider.module.css'
import clsx from 'clsx'
import { BasicStyledComponent, DSImageProps } from 'shared-definitions/types'
import AppImage from 'shared-components/ui/AppImage'
import GalleryNavigateButton from 'shared-components/buttons/GalleryNavigateButton'
import ArrowLeft from 'shared-svg/ArrowLeft.svg'
import { useEffect, useRef, useState } from 'react'

interface ImageSliderProps extends BasicStyledComponent {
  images: readonly DSImageProps[]
}

const gap = 15
const ImageSlider: React.FC<ImageSliderProps> = ({ children, className, images }) => {
  const slider = useRef<HTMLDivElement>(null)
  const itemsRef = useRef<(HTMLPictureElement | undefined | null)[]>([])

  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  /*
  version with intersection observer do not work correctly if browser do not support
  scroll-behavior: smooth
  (Safari)
  */
  useEffect(() => {
    const { current } = slider
    if (!current) {
      return
    }

    itemsRef.current = itemsRef.current.slice(0, images.length)
    const handler = (): void => {
      const calculated = (current.scrollLeft / current.scrollWidth) * images.length
      setCurrentImageIndex(Math.round(calculated))
    }

    current.addEventListener('scroll', handler, { passive: true })
    return () => {
      current.removeEventListener('scroll', handler)
    }
  }, [images])

  return (
    <div className={clsx(styles.container, className, { [styles.pending]: false })}>
      <div className={styles.slider} ref={slider}>
        {images.map((item, index) => (
          <AppImage
            ref={el => (itemsRef.current[index] = el)}
            className={styles.imageContainer}
            imageClassName={styles.image}
            layout="responsive"
            image={item}
            key={index}
            sizes={[{ bp: 'lg', val: 820 }, 'calc(100vw - rem(30))']}
          />
        ))}
      </div>
      {images.length > 1 ? (
        <>
          <GalleryNavigateButton
            onClick={() => {
              if (slider.current && currentImageIndex > 0) {
                slider.current.scrollLeft -= slider.current.clientWidth + gap
              }
            }}
            className={clsx(styles.prevButton, styles.button, {
              [styles.disabled]: currentImageIndex <= 0,
            })}
            icon={ArrowLeft}
          />
          <GalleryNavigateButton
            onClick={() => {
              if (slider.current && currentImageIndex < images.length - 1) {
                slider.current.scrollLeft += slider.current.clientWidth + gap
              }
            }}
            className={clsx(styles.nextButton, styles.button, {
              [styles.disabled]: currentImageIndex >= images.length - 1,
            })}
            icon={ArrowLeft}
          />
        </>
      ) : null}
      {children}
    </div>
  )
}

export default ImageSlider
