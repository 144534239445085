import styles from './IconedButton.module.css'
import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'

interface IconedButtonProps extends BasicStyledComponent {
  onClick: () => void
}

const IconedButton: React.FC<IconedButtonProps> = ({ className, children, onClick }) => (
  <button onClick={onClick} type="button" className={clsx(styles.button, className)}>
    {children}
  </button>
)

export default IconedButton
