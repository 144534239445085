import { DSNCSeeAlso, DSTpl } from 'shared-definitions/types'
import clsx from 'clsx'
import PreviewPostLink from 'shared-components/ui/PreviewPostLink'
import PreviewLargePostLink from 'shared-components/ui/PreviewLargePostLink'
import styleUtils from 'shared-components/styles/util.module.css'

interface NCSeeAlsoProps {
  data: DSNCSeeAlso
  tpl: DSTpl
}

const NCSeeAlso: React.VFC<NCSeeAlsoProps> = ({ data, tpl }) => {
  if (tpl === 'home') {
    return <PreviewLargePostLink {...data.pLink} className={clsx(styleUtils.nc)} post={data} />
  }

  const responsive = !['sidebar', 'in-column'].includes(tpl)
  return (
    <PreviewPostLink
      {...data.pLink}
      responsive={responsive}
      className={clsx(styleUtils.nc, { [styleUtils.mobileFull]: responsive })}
      post={data}
    />
  )
}

export default NCSeeAlso
