import { useEffect } from 'react'
import { BasicStyledComponent } from 'shared-definitions/types'

interface FreestarVideoProps {
  id: string
}

const FreestarVideo: React.VFC<FreestarVideoProps & BasicStyledComponent> = ({ id, className }) => {
  useEffect(() => {
    window.freestar?.queue.push(() => {
      console.log('Freestar newVideo', id)
      window.freestar?.newVideo(id)
    })
  }, [id])

  return (
    <div id={id} className={className}>
      <div id="freestar-video-parent">
        <div id="freestar-video-child" />
      </div>
    </div>
  )
}

export default FreestarVideo
