import styles from './CreditsLine.module.css'
import clsx from 'clsx'
import { BasicStyledComponent, DSCredit } from 'shared-definitions/types'
import CreditLink from 'shared-components/ui/CreditLink'

interface CreditsLineProps extends BasicStyledComponent {
  credits: readonly DSCredit[]
}

const CreditsLine: React.FC<CreditsLineProps> = ({ children, className, credits }) => (
  <div className={clsx(styles.container, className)}>
    {credits.map((item, index) => (
      <CreditLink credit={item} key={index} />
    ))}
    {children}
  </div>
)

export default CreditsLine
