import clsx from 'clsx'
import stylesUtils from 'shared-components/styles/util.module.css'
import { DSNCRevcontent } from 'shared-definitions/types'
import Freestar from '../ad/Freestar'
import styles from './NCAdFreestarMedRect.module.css'

interface NCAdFreestarMedRectProps {
  data: DSNCRevcontent
  index: number
}

const NCAdFreestarMedRect: React.VFC<NCAdFreestarMedRectProps> = ({ index }) => (
  <div className={clsx(styles.container, stylesUtils.nc)}>
    <Freestar slotId={`med_rect_${index}`} placementName="soundguys_BTF_leaderboard" />
  </div>
)

export default NCAdFreestarMedRect
