import { FreestarContext } from 'shared-components/contexts/FreestarContext'
import FreestarAdSlot from '@freestar/pubfig-adslot-react-component'
import { BasicStyledComponent } from 'shared-definitions/types'
import { logNotice } from 'shared-code/log'
import styles from './Freestar.module.css'

interface FreestarProps extends BasicStyledComponent {
  placementName: FreestarPlacementName
  slotId: string
  targeting?: Record<string, string>
  insertAdLabel?: boolean
}

const Freestar: React.VFC<FreestarProps> = ({
  placementName,
  slotId,
  targeting,
  className,
  insertAdLabel = false,
}) => {
  const { adClassList, publisher } = FreestarContext.useContainer()
  const splittedClasses = className ? className.split(' ') : []
  const insertIncontentDynamicAdLabel = (): void => {
    const adContainers = document.querySelectorAll(`#${placementName}:not(.hasLabel)`)
    adContainers.forEach(singleAd => {
      const container = document.createElement('div')
      const label = document.createElement('p')
      label.textContent = 'Advert Content'
      label.classList.add(styles.label)
      container.prepend(label)
      singleAd.classList.add('hasLabel')
      singleAd.prepend(container)
    })
  }

  return (
    <FreestarAdSlot
      publisher={publisher}
      placementName={placementName}
      slotId={slotId}
      targeting={targeting}
      channel="custom_channel"
      classList={[...splittedClasses, ...adClassList, styles.aligment]}
      onNewAdSlotsHook={(selectedPlacementName: string) => {
        logNotice('creating ad', selectedPlacementName)
        if (insertAdLabel && placementName.includes('soundguys_incontent_')) {
          insertIncontentDynamicAdLabel()
        }
      }}
      onDeleteAdSlotsHook={(selectedPlacementName: string) =>
        logNotice('destroying ad', selectedPlacementName)
      }
      onAdRefreshHook={(selectedPlacementName: string) =>
        logNotice('refreshing ad', selectedPlacementName)
      }
    />
  )
}

export default Freestar
