import clsx from 'clsx'
import { publicConfig } from 'config'
import { createSynchronousCache, updateSynchronousCache } from 'shared-code/cache'
import Freestar from 'shared-components/ad/Freestar'
import FreestarVideo from 'shared-components/ad/FreestarVideo'
import { ResponsiveContext } from 'shared-components/contexts/ResponsiveContext'
import { DSNCAdClassed } from 'shared-definitions/types'
import styles from './NCFreestar.module.css'

interface NCFreestarProps {
  data: DSNCAdClassed
  index: number
}

const NCFreestar: React.VFC<NCFreestarProps> = ({ data, index }) => {
  const { ready, md } = ResponsiveContext.useContainer()
  const lastIncontentAdPlacementData = createSynchronousCache('lastIncontentAdPlacement', {
    lastIncontentAdPlacement: 1,
  })
  const currentPlacementId = lastIncontentAdPlacementData.lastIncontentAdPlacement

  if (lastIncontentAdPlacementData.lastIncontentAdPlacement >= 5) {
    lastIncontentAdPlacementData.lastIncontentAdPlacement = 1
  } else {
    lastIncontentAdPlacementData.lastIncontentAdPlacement++
  }

  updateSynchronousCache('lastIncontentAdPlacement', {
    lastIncontentAdPlacement: lastIncontentAdPlacementData.lastIncontentAdPlacement,
  })

  const placementName = `soundguys_incontent_${currentPlacementId}`

  let show = ready
  if (show) {
    if (data.visible === 'desktop') {
      show = md
    } else if (data.visible === 'mobile') {
      show = !md
    }
  }

  return (
    <div
      className={clsx(styles.placeholder, ...publicConfig('adClassList'), {
        [styles.mobile]: data.visible === 'mobile',
        [styles.desktop]: data.visible === 'desktop',
      })}
    >
      {show && data.type === 'plain' && (
        <Freestar
          insertAdLabel={true}
          slotId={`incontent_nc_${index}`}
          placementName={placementName as FreestarPlacementName}
        />
      )}
      {show && data.type === 'video' && data.visible === 'mobile' && (
        <FreestarVideo id="FreeStarVideoAdContainer_Mobile" />
      )}
      {show && data.type === 'video' && data.visible === 'desktop' && (
        <FreestarVideo id="FreeStarVideoAdContainer" />
      )}
    </div>
  )
}

export default NCFreestar
