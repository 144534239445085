/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/jsx-no-literals */

import { NCAudioProps } from 'shared-definitions/types'
import { useAudioChart } from 'shared-components/hooks/use-audio-chart'
import { useEffect } from 'react'
import styles from './NCAudioChart.module.css'
import clsx from 'clsx'
import ArrowBottomBird from 'shared-svg/ArrowBottomBird.svg'
import { useTooltipsContainer } from 'shared-components/hooks/use-tooltips-container'
import composeRefs from '@seznam/compose-react-refs'
import { useScrollableLinksContainer } from 'shared-components/hooks/use-scrollbale-links-container'
import styleContent from 'shared-components/styles/content.module.css'

const NCAudioChart: React.VFC<NCAudioProps> = ({ data: { chart } }) => {
  const {
    filteredModels,
    isLoading,
    chartUrl,
    scrollPosition,
    dropdownOpen,
    filterValue,
    selectedModel,
    dropdownRef,
    dropdownFilterRef,
  
    showAlt,
    caption,
    alt,
    ref,
    filterModels,
    handleComparisonChange,
    updateLoadingState,
    resetFilter,
    updateDropdownVisibility,

  } = useAudioChart(chart)

  useEffect(() => {
    if (scrollPosition) {
      window.scrollTo(0, scrollPosition)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return (
    <div ref={ref} className={styles.container}>
      {isLoading && (
        <div className={styles.placeholder}>
          <p>{'Loading chart ...'}</p>
        </div>
      )}
      {!isLoading && showAlt && alt && (
        <div className={styles.placeholder}>
          <p>{alt}</p>
        </div>
      )}

      {!showAlt &&
        < object
          aria-label="Audio Chart"
          data={chartUrl}
          type="text/html"
          onLoad={() => {
            if (chartUrl) {
              updateLoadingState(false)
            }
          }}
          width="100%"
          height={isLoading ? '0' : '470'}
          aria-labelledby={alt}
        />

      }

      {
        <div
          className={clsx(styleContent.content, {
            [styles.captionContainer]: !!caption,
            [styles.hiddenCaptionContainer]: !caption || isLoading
          })}
          ref={composeRefs(useScrollableLinksContainer(), useTooltipsContainer())}
          dangerouslySetInnerHTML={{
            __html: !isLoading && caption ? caption : '',
          }}
        />
      }

      {selectedModel?.id && (
        <div className={clsx(styles.selectContainer, { [styles.selectContainerLoading]: isLoading })}>
          <span className={styles.selectLabel}>{'Comparison model'}</span>

          <div
            ref={dropdownRef}
            className={clsx(styles.dropdown, { [styles.dropdownOpen]: dropdownOpen })}
          >
            <div
              onClick={() => {
                updateDropdownVisibility(!dropdownOpen)
              }}
              role="button"
              tabIndex={0}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  updateDropdownVisibility(!dropdownOpen)
                }
              }}
              className={styles.currentSelectionContainer}
            >
              <span className={styles.currentSelection}>{selectedModel?.name}</span>
              <ArrowBottomBird className={clsx(styles.arrowIcon)} />
            </div>

            <div className={styles.dropdownContent}>
              <div className={styles.filterContainer}>
                <input
                  value={filterValue}
                  onChange={filterModels}
                  name="Filter"
                  ref={dropdownFilterRef}
                  className={styles.dropdownFilter}
                  type="text"
                  placeholder="Filter by name"
                />
                <button
                  type="button"
                  className={styles.resetFilter}
                  onClick={() => {
                    resetFilter()
                  }}
                >
                  &times;
                </button>
              </div>

              <ul className={styles.dropdownOptionsContainer}>
                {filteredModels &&
                  filteredModels.map((singleModel, index) => (
                    <li className={clsx(styles.dropdownOption)} key={index}>
                      <button
                        className={clsx(styles.dropdownOptionActionButton, {
                          [styles.selectedOption]: selectedModel?.id === singleModel.id,
                        })}
                        onClick={() => {
                          void handleComparisonChange(singleModel)
                        }}
                        type="button"
                      >
                        {selectedModel?.id === singleModel.id && (
                          <span className={styles.selectedCheckMark}>&#x2713;</span>
                        )}

                        {singleModel.product.name}
                      </button>
                    </li>
                  ))}

                {filteredModels && filteredModels.length < 1 && (
                  <li className={styles.emptyDropdownOption}>No matching model</li>
                )}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default NCAudioChart
