import { useEffect, useState, ChangeEvent, useRef, MutableRefObject, RefObject } from 'react'
import {
  VileModel,
  VileModelMeasure,
  VileModelMeasureResponse,
  VileModelResponse,
  DSAudioChartProps,
} from 'shared-definitions/types'
import { browserCached } from 'shared-code/cache'
import { useInView } from 'react-intersection-observer'
import { useAutoFocus } from 'shared-components/hooks/use-auto-focus'

interface NCAudioChartState {
  vileModels: VileModel[]
  filteredModels: VileModel[]
  isLoading: boolean
  selectedModel: {
    name: string | undefined
    id: number | undefined
  }
  scrollPosition: number | null
  dropdownOpen: boolean
  showAlt: boolean
  filterValue: string | undefined
  chartUrl: string | undefined
  dropdownRef: MutableRefObject<HTMLDivElement | null>
  dropdownFilterRef: RefObject<HTMLInputElement>
  caption?: string
  alt?: string

  ref: (node?: Element | null | undefined) => void
  filterModels: (event: ChangeEvent<HTMLInputElement>) => void
  handleComparisonChange: (singleModel: VileModel) => Promise<void>
  updateLoadingState: (state: boolean) => void
  updateDropdownVisibility: (state: boolean) => void
  resetFilter: () => void
}

export function useAudioChart(chart: DSAudioChartProps): NCAudioChartState {
  const {
    style,
    modelID,
    measureID,
    comparisonModelID,
    comparisonMeasurementID,
    targetID,
    nudge,
    limitComparisonModels,
    allowedComparisonModelsIds,
    api_url,
    caption,
    alt,
  } = chart

  const [vileModels, setVileModels] = useState<VileModel[]>([])
  const [filteredModels, setFilteredModels] = useState<VileModel[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [showAlt, setShowAlt] = useState<boolean>(false)
  const [selectedModel, setSelectedModel] = useState<{
    name: string | undefined
    id: number | undefined
  }>({ name: undefined, id: undefined })

  const [chartUrl, setChartUrl] = useState<string>()
  const [scrollPosition, setScrollPosition] = useState<number | null>(null)
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
  const [filterValue, setFilterValue] = useState<string>()
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const dropdownFilterRef = useAutoFocus<HTMLInputElement>(dropdownOpen)
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  const fetchModelMeasures = async (
    comparisonModelId: number
  ): Promise<VileModelMeasureResponse> => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const vileModelMeasuresResponse: VileModelMeasureResponse = await browserCached(
      `clearbuy-model-measures-${comparisonModelId}`,
      async () => {
        const response = await fetch(`${api_url}api/vile/measurements?id=${comparisonModelId}`, {
          method: 'GET',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
          },
        })

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const modelResponse: VileModelMeasure[] = await response.json()
        return { measures: modelResponse }
      }
    )

    return vileModelMeasuresResponse
  }
  const generateChartUrl = (comparisonModelId?: string, compMeasurementID?: string): void => {
    const baseRequest = new URL(`${api_url}vile/charts/${style}`)

    const params = new URLSearchParams()

    params.append('modelID', modelID)
    params.append('measureID', measureID)

    if (comparisonModelId) {
      params.append('comparisonModelID', String(comparisonModelId))
    }
    if (compMeasurementID) {
      params.append('comparisonMeasurementID', String(compMeasurementID))
    }
    if (targetID) {
      params.append('targetID', targetID)
    }
    if (nudge) {
      params.append('nudge', nudge)
    }

    baseRequest.search = params.toString()

    setChartUrl(baseRequest.toString())
  }

  const updateLoadingState = (state: boolean): void => {
    setIsLoading(state)
  }

  const updateDropdownVisibility = (visible: boolean): void => {
    setDropdownOpen(visible)
  }

  const resetFilter = (): void => {
    setFilteredModels(vileModels)
    setFilterValue('')
  }

  const handleComparisonChange = async (singleModel: VileModel): Promise<void> => {
    setScrollPosition(window.scrollY)
    setDropdownOpen(false)
    setSelectedModel({
      id: singleModel.id,
      name: singleModel.product.name,
    })
    setIsLoading(true)

    const { measures } = await fetchModelMeasures(singleModel.id)

    if (measures.length && measures.length > 0) {
      generateChartUrl(String(singleModel.id), String(measures[0].id))
    }
  }

  const filterModels = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault()
    const newFilter: string = event.target.value.toLowerCase()
    const newFilteredModels = vileModels.filter(singleModel =>
      singleModel.product.name.toLowerCase().includes(newFilter)
    )

    setFilterValue(newFilter)
    setFilteredModels(newFilteredModels)
  }

  useEffect(() => {
    const fetchModels = async (): Promise<void> => {
      setIsLoading(true)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const vileModelResponse: VileModelResponse = await browserCached(
        'clearbuy-models',
        async () => {
          const response = await fetch(`${api_url}api/vile/models`, {
            method: 'GET',
            cache: 'no-cache',
            headers: {
              'Content-Type': 'application/json',
            },
          })
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const modelResponse: VileModel[] = await response.json()

          return { models: modelResponse }
        }
      )

      const currentSelectedModel = vileModelResponse.models.find(
        singleModel => String(singleModel.id) === comparisonModelID
      )

      if (
        limitComparisonModels &&
        allowedComparisonModelsIds &&
        allowedComparisonModelsIds.length < 1
      ) {
        return
      }

      if (limitComparisonModels && comparisonModelID) {
        vileModelResponse.models = vileModelResponse.models.filter(
          singleModel =>
            allowedComparisonModelsIds?.includes(String(singleModel.id)) ||
            String(singleModel.id) === comparisonModelID
        )
      }

      if (currentSelectedModel) {
        setSelectedModel({
          id: currentSelectedModel?.id,
          name: currentSelectedModel?.product.name,
        })
      }
      setVileModels(vileModelResponse.models)
      setFilteredModels(vileModelResponse.models)
    }

    function handleClickOutside(event: MouseEvent): void {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    if (inView && !chartUrl) {
      void generateChartUrl(comparisonModelID, comparisonMeasurementID)
      void fetchModels()
    }

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  useEffect(() => {
    async function displayAlt(): Promise<void> {
      if (!chartUrl) {
        return
      }

      const vileChartHtmlResponseStatus = await browserCached(`${chartUrl}-response`, async () => {
        const response = await fetch(chartUrl, {
          method: 'GET',
        })
        return response.status
      })

      if (vileChartHtmlResponseStatus !== 200 && alt) {
        setShowAlt(true)
        setIsLoading(false)
      }
    }

    void displayAlt()
  }, [chartUrl])

  return {
    vileModels,
    filteredModels,
    selectedModel,
    scrollPosition,
    dropdownOpen,
    chartUrl,
    filterValue,
    showAlt,
    dropdownRef,
    dropdownFilterRef,
    isLoading,
    alt,
    caption: caption && caption?.length > 0 ? caption[0] : undefined,
    ref,
    filterModels,
    handleComparisonChange,
    updateLoadingState,
    resetFilter,
    updateDropdownVisibility,
  }
}
