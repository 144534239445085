import { useCallback } from 'react'
import { DSNCDealsSimple } from 'shared-definitions/types'
import styles from './NCDealsSimple.module.css'
import DealsSimple from 'shared-components/ncui/DealsSimple'
import styleUtils from 'shared-components/styles/util.module.css'
import EditButton from 'shared-components/service/EditButton'
import AccordionDeal, { StandardDealButton, StandardDealButtonProps } from '../layout/AccordionDeal'

interface NCDealsSimpleProps {
  data: DSNCDealsSimple
}

const NCDealsSimple: React.VFC<NCDealsSimpleProps> = ({ data }) => (
  <>
    <EditButton pLink={data.pLinkEdit} pLinkRequestUpdate={data.pLinkRequestUpdate} elevated />
    <DealsSimple
      dataAttrs={data.dataAttrs}
      title={data.title}
      moreLink={data.refLink}
      className={styleUtils.nc}
      msrp={data.msrp}
    >
      {data.anchor && (
        <a id={data.anchor} className={styleUtils.anchor}>
          {data.title}
        </a>
      )}
      <AccordionDeal
        data={data.buttons}
        view="bordered"
        className={styles.accordionDeal}
        asButton={useCallback(
          (injectedProps: StandardDealButtonProps) => (
            <StandardDealButton
              className={styles.button}
              data-sel="list-deal-button"
              {...injectedProps}
            />
          ),
          []
        )}
      />
    </DealsSimple>
  </>
)

export default NCDealsSimple
