/* eslint-disable promise/prefer-await-to-then */
import { BasicStyledComponent } from 'shared-definitions/types'
import Head from 'next/head'
import { useEffect, useRef, useState } from 'react'
import { ThemeContext } from 'shared-components/contexts/ThemeContext'
import { logError } from 'shared-code/log'
import styles from './TwitterTweet.module.css'

interface TwitterTweetProps extends BasicStyledComponent {
  id: string
  options: TwitterTweetOptions
}

const attr = 'tw'
const TwitterTweet: React.FC<TwitterTweetProps> = ({ className, children, id, options }) => {
  const { ready: themeReady, theme } = ThemeContext.useContainer()
  const ref = useRef<HTMLDivElement>(null)
  const [rendered, setRendered] = useState(false)
  const [complete, setComplete] = useState(false)
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.twttr) {
        clearInterval(interval)
        setInitialized(true)
      }
    }, 1)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (rendered || !initialized || !themeReady || !ref.current || !window.twttr) {
      return
    }

    try {
      // remove possible duplicates
      ref.current.querySelectorAll(`[${attr}=yes]`).forEach(n => n.remove())
    } catch (e) {
      logError('TwitterTweet', e)
    }

    // create child element to better content control
    const childEl = document.createElement('div')
    childEl.setAttribute(attr, 'yes')
    childEl.setAttribute('class', styles.container)
    ref.current.appendChild(childEl)

    // twitter api can mutate options object
    const opts = { ...options, theme }
    void window.twttr.widgets.createTweet(id, childEl, opts).then(el => {
      if (el) {
        setComplete(true)
      }
    })
    setRendered(true)
  }, [id, options, rendered, theme, themeReady, initialized])

  useEffect(() => {
    setRendered(false)
    setComplete(false)
  }, [id])

  return (
    <>
      <Head>
        <script defer key="twitter-widgets" src="https://platform.twitter.com/widgets.js" />
      </Head>
      <div className={className} ref={ref}>
        {complete ? null : children}
      </div>
    </>
  )
}

export default TwitterTweet
