import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import styles from './Accordion.module.css'

interface AccordionElementProps {
  children: React.ReactElement
  open: boolean
  className?: string
}

const AccordionElement: React.VFC<AccordionElementProps> = ({ className, children, open }) => {
  const [maxHeight, setMaxHeight] = useState(0)
  const el = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!el.current) {
      return
    }

    setMaxHeight(el.current.scrollHeight)
  }, [setMaxHeight])

  return (
    <div
      style={{ height: open ? `${maxHeight}px` : 0 }}
      ref={el}
      className={clsx(className, styles.container)}
    >
      {children}
    </div>
  )
}

export default AccordionElement
