import { DSNCRecommend } from 'shared-definitions/types'
import RecommendList from 'shared-components/ncui/RecommendList'
import { getds } from 'ds'
import { useCallback } from 'react'

interface NCRecommendProps {
  data: DSNCRecommend
}

const NCRecommend: React.VFC<NCRecommendProps> = () => (
  <RecommendList
    getRecommend={useCallback(async (...args) => {
      const ds = await getds()
      const result = await ds.getRecommend(...args)
      return result.data.recommendations?.items ?? []
    }, [])}
  />
)

export default NCRecommend
