import { DSNCSpecTable } from 'shared-definitions/types'
import styleUtils from 'shared-components/styles/util.module.css'
import styles from './NCSpecTable.module.css'
import ArrowBottomBird from 'shared-svg/ArrowBottomBird.svg'
import IconedButton from 'shared-components/buttons/IconedButton'
import Nodes from 'shared-components/ui/Nodes'
import clsx from 'clsx'
import { CSSProperties, useState } from 'react'
import { useScrollHeight } from 'shared-components/hooks/use-scroll-height'
import EditButton from 'shared-components/service/EditButton'

interface NCSpecTableProps {
  data: DSNCSpecTable
}

const NCSpecTable: React.VFC<NCSpecTableProps> = ({ data }) => {
  const { ref, height } = useScrollHeight<HTMLDivElement>()
  const [opened, setOpened] = useState(!data.collapsable)
  return (
    <div
      className={styleUtils.nc}
      style={{ '--nodes-scroll-height': `${height}px` } as CSSProperties}
    >
      <EditButton pLink={data.pLinkEdit} elevated pLinkRequestUpdate={null} />
      <div className={styles.title}>{data.title}</div>
      <div className={styles.nodesContainer}>
        <Nodes
          ref={ref}
          table={data.table}
          variant={opened ? 'expanded' : 'collapsed'}
          limit={opened ? undefined : 6}
        />
      </div>
      {data.collapsable ? (
        <IconedButton
          onClick={() => setOpened(s => !s)}
          className={clsx(styles.button, { [styles.buttonActive]: opened })}
        >
          {opened ? 'Collapse' : 'Expand'}
          <ArrowBottomBird className={styles.buttonIcon} />
        </IconedButton>
      ) : null}
    </div>
  )
}

export default NCSpecTable
