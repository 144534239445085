/* eslint-disable react/jsx-no-bind */
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import { ComponentType, CSSProperties, ReactElement, useState } from 'react'
import { PopupContext } from 'shared-components/contexts/PopupContext'
import { useScrollHeight } from 'shared-components/hooks/use-scroll-height'
import styleUtils from 'shared-components/styles/util.module.css'
import AppImage from 'shared-components/ui/AppImage'
import { DSNCFaq, DSResource, DSTpl, NCComponentInterface } from 'shared-definitions/types'
import ArrowBottomBird from 'shared-svg/ArrowBottomBird.svg'
import CommentQuestion from 'shared-svg/CommentQuestion.svg'
import styles from './NCFaq.module.css'

const ImageBlockPopup = dynamic(() => import('shared-components/ui/ImageBlockPopup'))

interface NCFaqProps<T extends DSResource> {
  data: DSNCFaq<T>
  tpl: DSTpl
  nc: ComponentType<NCComponentInterface<T>>
}

const NCFaq = <T extends DSResource>({ data, nc: NC, tpl }: NCFaqProps<T>): ReactElement => {
  const { setPopupOpened, setPopupContent } = PopupContext.useContainer()
  const { ref, height } = useScrollHeight<HTMLDivElement>()
  const [opened, setOpened] = useState(false)

  function zoomIn(): void {
    if (!data.image) {
      return
    }
    setPopupContent(
      <ImageBlockPopup
        className={styles.popupBlock}
        image={data.image}
        onClose={() => setPopupOpened(false)}
      />,
      styles.popupContainer
    )
    setPopupOpened(true)
  }

  return (
    <div className={clsx(styleUtils.nc, styles.container, { [styles.opened]: opened })}>
      <button type="button" className={styles.openButton} onClick={() => setOpened(s => !s)}>
        {data.icon === 'ask-question' ? (
          <CommentQuestion className={styles.openButtonLeftIcon} />
        ) : null}
        <span className={styles.buttonTitle}>{data.question}</span>
        <ArrowBottomBird className={styles.openButtonIcon} />
      </button>
      <div
        className={styles.body}
        ref={ref}
        style={{ '--scroll-height': `${height}px` } as CSSProperties}
      >
        {data.image ? (
          <button type="button" className={styles.imageButton} onClick={zoomIn}>
            <AppImage
              image={data.image}
              layout="responsive"
              sizes={[{ bp: 'sm', val: 130 }, 'calc(100vw - rem(30))']}
            />
          </button>
        ) : null}
        <div>
          {data.blocks.map((item, index) => (
            <NC key={index} index={index} data={item} tpl={tpl} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default NCFaq
