import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { PostContext } from 'shared-components/contexts/PostContext'
import PostCardRelated from 'shared-components/posts/PostCardRelated'
import styleUtils from 'shared-components/styles/util.module.css'
import { DSPostShortSearch, DSPostShortWithAuthor } from 'shared-definitions/types'
import styles from './RecommendList.module.css'

interface RecommendListProps {
  getRecommend: (
    postId: number,
    from: number,
    to: number,
    maxItems: number
  ) => Promise<DSPostShortSearch[]>
}

const RecommendList: React.VFC<RecommendListProps> = ({ getRecommend }) => {
  const { postId } = PostContext.useContainer()
  const [items, setItems] = useState<DSPostShortWithAuthor[]>([])
  const [requestMade, setRequestMade] = useState(false)
  const { ref, inView } = useInView({ triggerOnce: true, rootMargin: '710px' })

  useEffect(() => {
    if (!postId || !inView) {
      setItems(s => (s.length ? [] : s))
      return
    }

    void (async () => {
      const result = await getRecommend(postId, 0, 3, 11)

      if (!result.length) {
        setRequestMade(true)
        return
      }

      setRequestMade(true)
      setItems(result)
    })()
  }, [postId, inView, getRecommend])

  return (
    <div
      ref={ref}
      className={clsx(styles.container, styleUtils.nc, {
        [styles.lazy]: !requestMade,
        [styles.empty]: requestMade && items.length === 0,
      })}
    >
      {items.map((item, index) => (
        <PostCardRelated
          key={index}
          post={item}
          className={styles.item}
          data-sel="related-content-link"
        />
      ))}
    </div>
  )
}

export default RecommendList
