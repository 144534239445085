import clsx from 'clsx'
import styleUtils from 'shared-components/styles/util.module.css'
import { DSNCDealsLatest } from 'shared-definitions/types'
import ArrowRightShade from 'shared-svg/ArrowRightShade.svg'
import Linked from '../service/Linked'
import DealsLatestCard from '../ui/DealsLatestCard'
import StackAccordion from '../ui/StackAccordion'
import styles from './NCDealsLatest.module.css'

interface NCDealsLatestProps {
  data: DSNCDealsLatest
}

const NCDealsLatest: React.FC<NCDealsLatestProps> = ({ data }) => (
  <div className={clsx(styleUtils.nc, styles.container)}>
    <h4 className={styles.title}>{data.title}</h4>
    <Linked {...data.button.pLink} className={styles.button}>
      {data.button.label}
      <ArrowRightShade className={styles.buttonIcon} />
    </Linked>
    <StackAccordion
      className={clsx(styles.cards, styleUtils.mobileFull)}
      items={data.items}
      render={item => <DealsLatestCard {...item} />}
    />
  </div>
)

export default NCDealsLatest
