import { useCallback } from 'react'
import { DSNCDealsButton } from 'shared-definitions/types'
import clsx from 'clsx'
import styleUtils from 'shared-components/styles/util.module.css'
import styles from './NCDealsButton.module.css'
import EditButton from 'shared-components/service/EditButton'
import AccordionDeal, { StandardDealButton, StandardDealButtonProps } from '../layout/AccordionDeal'

interface NCDealsButtonProps {
  data: DSNCDealsButton
}

const NCDealsButton: React.VFC<NCDealsButtonProps> = ({ data }) => (
  <>
    <EditButton pLink={data.pLinkEdit} pLinkRequestUpdate={data.pLinkRequestUpdate} elevated />
    <div {...data.dataAttrs} className={clsx(styleUtils.nc, styles.container)}>
      {data.anchor && (
        <a id={data.anchor} className={styleUtils.anchor}>
          {data.buttons[0].link.label}
        </a>
      )}
      <AccordionDeal
        data={data.buttons}
        view="bordered"
        asButton={useCallback(
          (injectedProps: StandardDealButtonProps) => (
            <StandardDealButton data-sel="large-deal-button" {...injectedProps} />
          ),
          []
        )}
      />
    </div>
  </>
)

export default NCDealsButton
