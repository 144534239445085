import clsx from 'clsx'
import styleUtils from 'shared-components/styles/util.module.css'
import React, { useCallback } from 'react'
import EditButton from 'shared-components/service/EditButton'
import stylesDeals from 'shared-components/styles/deals.module.css'
import AppImage from 'shared-components/ui/AppImage'
import { BasicStyledComponent, DSDealShort } from 'shared-definitions/types'
import viewport from 'shared-definitions/viewport.json'
import AccordionDeal, { StandardDealButton, StandardDealButtonProps } from '../layout/AccordionDeal'
import styles from './DealShortCard.module.css'

interface DealShortCardProps extends BasicStyledComponent {
  item: DSDealShort
}

const DealShortCard: React.VFC<DealShortCardProps> = ({ className, item }) => {
  const { discountPercent } = item.buttons[0].price
  return (
    <div {...item.dataAttrs} className={clsx(styles.container, className)}>
      {item.anchor && (
        <a id={item.anchor} className={styleUtils.anchor}>
          {item.buttons[0].link.label}
        </a>
      )}
      <EditButton absolute pLink={item.pLinkEdit} pLinkRequestUpdate={item.pLinkRequestUpdate} />
      <div className={clsx(styles.up, stylesDeals.border)}>
        <AppImage
          image={item.image}
          sizes={[
            { bp: 'md', val: `calc(min(calc(100vw - rem(30)), rem(${viewport['lg']})) / 3)` },
            { bp: 'sm', val: 'calc(calc(100vw - rem(30)) / 2)' },
            'calc(100vw - rem(30))',
          ]}
          layout="responsive"
          ratio={{ w: 16, h: 9 }}
        />
        {discountPercent ? (
          <div className={styles.percent}>
            <span className={styles.percentVal}>
              {discountPercent}
              {'%'}
            </span>
            {'off'}
          </div>
        ) : null}
      </div>
      <div className={clsx(styles.down, stylesDeals.bg, stylesDeals.border)}>
        <div className={styles.title}>{item.title}</div>
        <div className={styles.tags}>
          {item.tags.map((tag, index) => (
            <React.Fragment key={index}>
              {tag}
              <br />
            </React.Fragment>
          ))}
        </div>

        <AccordionDeal
          data={item.buttons}
          view="bordered"
          asButton={useCallback(
            (injectedProps: StandardDealButtonProps) => (
              <StandardDealButton data-sel="medium-deal-button" {...injectedProps} />
            ),
            []
          )}
        />
      </div>
    </div>
  )
}

export default DealShortCard
