/* eslint-disable react/display-name */
import clsx from 'clsx'
import React from 'react'
import Linked from 'shared-components/service/Linked'
import AppImage from 'shared-components/ui/AppImage'
import { BasicStyledComponent, DSDataAttrs, DSPostShortWithAuthor } from 'shared-definitions/types'
import { UIVariantContext } from '../contexts/UIVariantContext'
import styles from './PostCardRelated.module.css'
import dynamic from 'next/dynamic'

const ScoreLine = dynamic(() => import('shared-components/ui/Score'))
const ScoreRing = dynamic(() => import('shared-components/ui/ScoreRing'))

interface PostCardRelatedProps extends BasicStyledComponent, DSDataAttrs {
  post: DSPostShortWithAuthor
  hideCategory?: boolean
  unresponsive?: boolean
}

const PostCardRelated: React.VFC<PostCardRelatedProps> = React.memo(
  ({ hideCategory, post, className, unresponsive, ...props }) => {
    const { postPreviewScore } = UIVariantContext.useContainer()

    return (
      <Linked
        className={clsx(styles.container, className, { [styles.unresponsive]: unresponsive })}
        {...post.pLink}
        {...props}
      >
        <div className={styles.info}>
          <div className={styles.title}>{post.title}</div>
          {(!hideCategory && post.category) || post.sponsored ? (
            <div className={styles.tag}>{post.sponsored ? 'Promoted' : post.category}</div>
          ) : null}
        </div>
        {post.image && (
          <>
            <AppImage
              className={styles.img}
              image={post.image}
              layout="raw"
              sizes={[{ bp: 'sm', val: 282 }, 90]}
            />
            {post.score && postPreviewScore === 'line' ? (
              <ScoreLine className={clsx(styles.scoreLine)} score={post.score} />
            ) : null}
            {post.score && postPreviewScore === 'ring' ? (
              <ScoreRing className={clsx(styles.scoreRing)} score={post.score} />
            ) : null}
            {post.badge ? (
              <AppImage
                image={post.badge}
                imageClassName={styles.badgeImage}
                className={clsx(styles.badge)}
                sizes={[{ bp: 'md', val: 72 }, 40]}
                layout="raw"
              />
            ) : null}
          </>
        )}
      </Linked>
    )
  }
)

export default PostCardRelated
